<template>
  <v-app>
    <section
      class="header"
      id="header"
      :class="{ fixed: scrollPosition > 150 }"
    >
      <div class="nav-logo">
        <a
          v-scroll-to="{
            el: '#header',
          }"
          ><img class="logo" src="@/assets/images/logo.png"
        /></a>
      </div>
      <span
        class="logo-dash"
        :class="{ dash_fixed: scrollPosition > 150 }"
      ></span>
      <div class="nav-list" :class="menu == 'open'? 'menu-open' : ''">
        <ul>
          <li class="tab navLink">
            <a
              v-scroll-to="{
                el: '#services',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'pt'"
              >Como fazemos</a
            >
            <a
              v-scroll-to="{
                el: '#services',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'en'"
              >How we do</a
            >
            <span></span>
          </li>
          <li class="tab navLink">
            <a
              v-scroll-to="{
                el: '#portfolio',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'pt'"
              >O que fazemos</a
            >
            <a
              v-scroll-to="{
                el: '#portfolio',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'en'"
              >What we do</a
            >
            <span></span>
          </li>
          <li class="tab navLink">
            <a
              v-scroll-to="{
                el: '#about',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'pt'"
              >Sobre nós</a
            >
            <a
              v-scroll-to="{
                el: '#about',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'en'"
              >About Us</a
            >
            <span></span>
          </li>
          <li class="tab navLink">
            <a
              v-scroll-to="{
                el: '#form',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'pt'"
              >Contactos</a
            >
            <a
              v-scroll-to="{
                el: '#form',
                offset: -110,
              }"
              @click="menu = ''"
              v-if="lang == 'en'"
              >Contacts</a
            >
          </li>
          <li class="tab navLink">
            <a v-if="lang == 'en'" @click="changeLangPT()">PT</a>
            <a v-if="lang == 'pt'" @click="changeLangEN()">EN</a>
            <span></span>
          </li>
        </ul>
      </div>
      <a id="hamburger" class="menuBtn" :class="menu == 'open'? 'menu-open' : ''" @click="openMenu()"><span></span></a>
    </section>
    <v-main>
      <router-view :lang="lang"/>
    </v-main>
    <section class="footer">
      <div class="wrapperL">
        <div class="footer-logo">
          <a
            v-scroll-to="{
              el: '#header',
            }"
            ><img class="logo" src="@/assets/images/logo2.png"
          /></a>
        </div>
        <div class="footer-links">
          <ul>
            <li>
              <a href="mailto:geral@linqode.com">geral@linqode.com</a>
            </li>
            <li>
              <a v-if="lang == 'pt'" href=""
                >Todos os direitos reservados © Linqode
                {{ new Date().getFullYear() }}</a
              >
              <a v-if="lang == 'en'" href=""
                >All rights reserved © Linqode
                {{ new Date().getFullYear() }}</a
              >
            </li>
            <li>
              <a
                v-scroll-to="{
                  el: '#header',
                }"
              >
                <img alt="top-arrow" src="@/assets/images/arrow.png" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created () {
    if (sessionStorage.getItem("store") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    } 

    window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  },

  data: () => ({
    scrollPosition: null,
    // lang: "pt",
    menu:'',
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openMenu(){
      if(this.menu == ''){
        this.menu = 'open'
      } else{
        this.menu = ''
      }
    },
    changeLangPT(){
      this.$store.dispatch('changePT');
      this.menu = ''
    },
     changeLangEN(){
      this.$store.dispatch('changeEN');
      this.menu = ''
    }
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    lang(){
      return this.$store.getters.getLang;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  padding: 50px;
  position: absolute;

  &.fixed {
    background-color: white;
    // -webkit-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    //   -moz-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    //   box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
    position: fixed;
    padding: 30px 50px;
    z-index: 100;
    @include responsive(0, 512px) {
    padding: 30px 20px;
  }
  }

  @include responsive(0, 512px) {
    padding: 30px 20px;
  }
  .logo {
    width: 150px;
    @include responsive(0, 512px) {
      width: 100px;
    }
  }
  .logo-dash {
    display: block;
    width: 54px;
    height: 7px;
    background-color: black;
    position: absolute;
    left: 52px;
    top: 94px;
    animation: logo 3s ease-out 1s alternate infinite none running;
    &.dash_fixed {
      top: 74px;
    }
    @include responsive(0, 512px) {
      top: 59px;
      height: 4px;
      left: 20px;
      animation: logo-mobile 3s ease-out 1s alternate infinite none running;
      &.dash_fixed {
      top: 59px;
    }
    }
  }
  .nav-logo {
    width: 30%;
    display: inline-block;
    vertical-align: top;
  }
  .nav-list {
    width: 70%;
    display: inline-block;
    vertical-align: top;
    ul {
      text-align: right;
      li {
        display: inline-block;
        vertical-align: top;
        &:hover {
          a {
            font-weight: 600;
          }
          span {
            display: block;
          }
        }
        a {
          text-decoration: none;
          color: black;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: $medium;
          line-height: 100px;
          margin-left: 25px;
          line-height: 44px;
          font-family: "Montserrat", sans-serif;
        }
        span {
          display: none;
          width: calc(100% - 25px);
          height: 5px;
          background-color: black;
          position: relative;
          float: right;
          margin-top: -5px;
        }
      }
    }
    @include responsive(0, 768px){
      display: none;
    }
    &.menu-open {
      display: block;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0px;
      left: 0%;
      ul {
        text-align: center;
        background-color: black;
        padding: 60px 0px;
        min-height: 100vh;
        li {
          display: block;
          padding: 20px 0;
          &:hover {
            background-color: rgb(38, 38, 38);
            color: white;
            a {
              color: white;
            }
            span {
              display: none;
            }
          }
          a {
            font-size: 18px;
            font-weight: $bold;
            margin-left: 0px;
            display: block;
            color: white;
          }
        }
      }
    }
  }
  #hamburger{
  display:none;
  position: absolute;
  top: 35px;
  right: 20px;
  width: 30px;
  height: 18px;
  @include responsive(0, 768px){
    display: block;
  }
  span{
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    right: 0px;
    background: black;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    top:50%;
    transform:translateY(-50%);
  }
  &:before{
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    right: 0px;
    background: black;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    content:"";
    top:0;
  }
  &:after{
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    right: 0px;
    background: black;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    content:"";
    bottom:0;
  }
  &.menu-open{
    margin-top: 10px;
    span{
      opacity: 0;
    }
    &:before{
      width: 80%;
      top: calc(0);
      transform-origin: 14px;
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: white;
    }
    &:after{
      width: 80%;
      bottom: calc(17.5px);
      transform-origin: 12.5px;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      background-color: white;
    }
  }
}
}
// .footer {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   text-align: center;
//   height: 50px;
//   z-index: 30;
//   .contacts {
//     text-decoration: none;
//     font-size: 15px;
//     font-family: "Montserrat", sans-serif;
//     color: black;
//     &:hover {
//       font-weight: 600;
//     }
//   }

// }

.footer {
  width: 100%;
  background-color: white;
  position: relative;
  padding: 10px 50px;
  @include responsive(0, 768px) {
    height: auto;
    padding: 10px 30px;
  }
  .wrapperL {
    .footer-logo {
      width: 30%;
      display: inline-block;
      img {
        height: 40px;
        margin-top: 30px;
      }
      @include responsive(0, 768px) {
        width: 100%;
        display: block;
        text-align: center;
        padding-bottom: 20px;
        img {
          height: 30px;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
    }
    .footer-links {
      width: 70%;
      display: inline-block;
      text-align: right;
      vertical-align: top;
      ul {
        li {
          display: inline-block;
          margin-left: 25px;
          vertical-align: top;
          a {
            text-decoration: none;
            color: black;
            font-size: 12px;
            line-height: 100px;
            img {
              width: 28px;
              vertical-align: middle;
              transform: rotate(180deg);
              padding-bottom: -5px;
            }
          }
        }
        li:nth-child(1) {
          font-weight: $bold;
        }
        li:nth-child(3) {
          margin-top: 10px;
        }
      }
      @include responsive(0, 768px) {
        width: 100%;
        display: block;
        ul {
          li {
            display: block;
            text-align: center;
            margin: 0 auto;
            a {
              line-height: 20px;
              img {
                padding-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes logo {
  0% {
    width: 0px;
  }
  80% {
    width: 54px;
  }
  100% {
    width: 30px;
  }
}

@keyframes logo-mobile {
  0% {
    width: 0px;
  }
  80% {
    width: 30px;
  }
  100% {
    width: 20px;
  }
}

@keyframes contacts {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
