var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('section',{staticClass:"header",class:{ fixed: _vm.scrollPosition > 150 },attrs:{"id":"header"}},[_c('div',{staticClass:"nav-logo"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: '#header',
        }),expression:"{\n          el: '#header',\n        }"}]},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo.png")}})])]),_c('span',{staticClass:"logo-dash",class:{ dash_fixed: _vm.scrollPosition > 150 }}),_c('div',{staticClass:"nav-list",class:_vm.menu == 'open'? 'menu-open' : ''},[_c('ul',[_c('li',{staticClass:"tab navLink"},[(_vm.lang == 'pt')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#services',
              offset: -110,
            }),expression:"{\n              el: '#services',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("Como fazemos")]):_vm._e(),(_vm.lang == 'en')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#services',
              offset: -110,
            }),expression:"{\n              el: '#services',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("How we do")]):_vm._e(),_c('span')]),_c('li',{staticClass:"tab navLink"},[(_vm.lang == 'pt')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#portfolio',
              offset: -110,
            }),expression:"{\n              el: '#portfolio',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("O que fazemos")]):_vm._e(),(_vm.lang == 'en')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#portfolio',
              offset: -110,
            }),expression:"{\n              el: '#portfolio',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("What we do")]):_vm._e(),_c('span')]),_c('li',{staticClass:"tab navLink"},[(_vm.lang == 'pt')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#about',
              offset: -110,
            }),expression:"{\n              el: '#about',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("Sobre nós")]):_vm._e(),(_vm.lang == 'en')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#about',
              offset: -110,
            }),expression:"{\n              el: '#about',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("About Us")]):_vm._e(),_c('span')]),_c('li',{staticClass:"tab navLink"},[(_vm.lang == 'pt')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#form',
              offset: -110,
            }),expression:"{\n              el: '#form',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("Contactos")]):_vm._e(),(_vm.lang == 'en')?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#form',
              offset: -110,
            }),expression:"{\n              el: '#form',\n              offset: -110,\n            }"}],on:{"click":function($event){_vm.menu = ''}}},[_vm._v("Contacts")]):_vm._e()]),_c('li',{staticClass:"tab navLink"},[(_vm.lang == 'en')?_c('a',{on:{"click":function($event){return _vm.changeLangPT()}}},[_vm._v("PT")]):_vm._e(),(_vm.lang == 'pt')?_c('a',{on:{"click":function($event){return _vm.changeLangEN()}}},[_vm._v("EN")]):_vm._e(),_c('span')])])]),_c('a',{staticClass:"menuBtn",class:_vm.menu == 'open'? 'menu-open' : '',attrs:{"id":"hamburger"},on:{"click":function($event){return _vm.openMenu()}}},[_c('span')])]),_c('v-main',[_c('router-view',{attrs:{"lang":_vm.lang}})],1),_c('section',{staticClass:"footer"},[_c('div',{staticClass:"wrapperL"},[_c('div',{staticClass:"footer-logo"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#header',
          }),expression:"{\n            el: '#header',\n          }"}]},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo2.png")}})])]),_c('div',{staticClass:"footer-links"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"mailto:geral@linqode.com"}},[_vm._v("geral@linqode.com")])]),_c('li',[(_vm.lang == 'pt')?_c('a',{attrs:{"href":""}},[_vm._v("Todos os direitos reservados © Linqode "+_vm._s(new Date().getFullYear()))]):_vm._e(),(_vm.lang == 'en')?_c('a',{attrs:{"href":""}},[_vm._v("All rights reserved © Linqode "+_vm._s(new Date().getFullYear()))]):_vm._e()]),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#header',
              }),expression:"{\n                el: '#header',\n              }"}]},[_c('img',{attrs:{"alt":"top-arrow","src":require("@/assets/images/arrow.png")}})])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }