<template>
  <div>
    <section class="banner">
      <img class="people1" src="@/assets/images/people1.png" />
      <img class="people2" src="@/assets/images/people2.png" />
      <p class="signature">
        lean systems
        <span class="bold">linked people</span>
      </p>
      <!-- <p class="breve">BREVEMENTE</p> -->
      <div class="us">
        <p v-if="lang == 'pt'">
          Trabalhamos desde o estudo da experiência de utilização até ao
          desenvolvimento das ferramentas de forma a conectar pessoas através de
          sistemas digitais eficazes.
        </p>
        <p v-if="lang == 'en'">
          We work from the user experience knowledge to tools development in order to connect people through effective digital systems.
        </p>
        <a
          v-scroll-to="{
            el: '#contacts',
            offset: -110,
          }"
          v-if="lang == 'pt'"
          >Fale connosco!<span class="btn-dash"></span
        ></a>
        <a
          v-scroll-to="{
            el: '#contacts',
            offset: -110,
          }"
          v-if="lang == 'en'"
          >Let's Talk!<span class="btn-dash"></span
        ></a>
      </div>
      <div class="bg">
        <span class="dash dash1"></span>
      </div>
      <div class="bg2">
        <div>
          <span class="dash dash2"></span>
          <span class="dash dash3"></span>
        </div>
        <div>
          <span class="dash dash4"></span>
          <span class="dash dash5"></span>
        </div>
      </div>
      <!-- <a class="next"><img class="arrow" src="@/assets/images/arrow.png" /></a> -->
    </section>

    <section id="services" class="services">
      <div class="wrapper">
        <h2 v-if="lang == 'pt'">Como fazemos</h2>
        <h2 v-if="lang == 'en'">How we do</h2>
        <p class="sub-title" v-if="lang == 'pt'">
          A nossa missão é concretizar a visão dos nossos clientes. Para isso,
          trabalhamos em diversas linguagem e plataformas que nos permitem
          entregar soluções 'chave na mão'.
        </p>
        <p class="sub-title" v-if="lang == 'en'">
          Our mission is to fulfill our clients vision. In that way, we work
          with different programming languages and platforms that allow us to
          deliever end-to-end solution.
        </p>
        <div class="services-articles">
          <article>
            <h4>WEB</h4>
            <h3>DESIGN</h3>
            <p v-if="lang == 'pt'">Desenhamos para a web</p>
            <p v-if="lang == 'en'">Design for the web</p>
          </article>
          <article>
            <h4>HTML</h4>
            <h3>HTML</h3>
            <p v-if="lang == 'pt'">Desenvolvemos para todas as plataformas</p>
            <p v-if="lang == 'en'">Develop for every platform</p>
          </article>
          <article>
            <h4>CSS</h4>
            <h3>CSS/SASS</h3>
            <p v-if="lang == 'pt'">Personalizamos o que críamos</p>
            <p v-if="lang == 'en'">Personalize our creations</p>
          </article>
          <article>
            <h4>JS</h4>
            <h3>JAVASCRIPT</h3>
            <p v-if="lang == 'pt'">Integramos com todos os sistemas</p>
            <p v-if="lang == 'en'">Integrate with every system</p>
          </article>
          <article>
            <h4>PHP</h4>
            <h3>PHP</h3>
            <p v-if="lang == 'pt'">Desenvolvemos qualquer ferramenta</p>
            <p v-if="lang == 'en'">Develop new tools</p>
          </article>
          <article>
            <h4>SQL</h4>
            <h3>MySQL</h3>
            <p v-if="lang == 'pt'">Armazenamos e comunicamos</p>
            <p v-if="lang == 'en'">Store and comunicate</p>
          </article>
          <article>
            <h4>VUE</h4>
            <h3>VUE.JS</h3>
            <p v-if="lang == 'pt'">
              Simplificamos o desenvolvimento e a manutenção
            </p>
            <p v-if="lang == 'en'">Simplify the development and the upkeep</p>
          </article>
        </div>
      </div>
    </section>

    <section id="portfolio" class="portfolio">
      <h2 v-if="lang == 'pt'">O que fazemos</h2>
      <h2 v-if="lang == 'en'">What we do</h2>
      <p class="sub-title" v-if="lang == 'pt'">
        Desenvolvemos produtos digitais com diferentes objectivos e para
        diversos sectores.<br /><span class="bold"
          >Websites institucionais</span
        >
        com ou sem <i>backoffice</i> para gestão de conteúdo.
        <span class="bold">Plataformas com acesso limitado</span> a registo e
        <i>login</i>. <span class="bold">Lojas online</span> desenvolvidas à
        medida dos nossos clientes.
        <span class="bold">Ferramentas de trabalho</span> para operações.
        <span class="bold">Ferramentas de <i>report</i> e análise</span> para
        equipas internas. Trabalhamos entre outros, para o sector farmacêutico,
        de contact centers, retalho alimentar, educação, energético,
        contabilidade, advocacia.<br />Conheça alguns dos nossos trabalhos.
      </p>
      <p class="sub-title" v-if="lang == 'en'">
        We develop digital products with different goals for diverse areas. <span class="bold">Institutional websites</span> with or without content management systems. <span class="bold">Platforms with limited access</span> by registration and login. <span class="bold">E-commerce</span> developed to suit customer’s needs. <span class="bold">Work tools</span> for operational teams. <span class="bold">Reporting and analysis tools</span> for internal teams. We collaborate with different areas including, pharmaceutical sector, contact centers, food retail, education, energy, accounting and law sectors…<br>Discover some of our projects.

      </p>
      <ul>
        <li v-for="(item, i) in projects" :key="i">
          <v-card class="img-container">
            <v-carousel
              hide-delimiters
              :show-arrows="item.images.length > 1"
              height="auto"
            >
              <v-carousel-item v-for="(img, i) in item.images" :key="i">
                <img
                  :src="'./assets/images/' + img.src"
                  style="height: 600px; width: 100%; height: auto"
                />
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <div>
            <h2>{{ item.name }}</h2>
            <p class="subtitle">{{ item.subtitle }}</p>
            <p v-if="lang == 'pt'" v-html="item.text"></p>
            <p v-if="lang == 'en'" v-html="item.textEN"></p>
            <a v-if="item.link != ''" target="_blank" :href="item.link">+</a>
          </div>
        </li>
      </ul>
    </section>

    <section id="about" class="about">
      <div class="wrapper">
        <div class="about-content">
          <h2 v-if="lang == 'pt'">Sobre nós</h2>
          <h2 v-if="lang == 'en'">About Us</h2>
          <p v-if="lang == 'en'">
            A young and dynamic team, with several years of experience and
            knowledge from working with many brands online.
            <span class="b">Designers</span> that can create, adapt and
            integrate any brand in a digital environment in a way that will
            promote user interaction. <span class="b">Developers</span> with the
            abillity to transform any dream in a complete and functional digital
            product that will work on any device.
            <span class="b">Programmers</span> capable of integrate any tool or
            functionality.
          </p>
          <p v-if="lang == 'pt'">
            Somos uma equipa jovem, dinâmica e com vários anos de experiência e
            aprendizagem a trabalhar em diferentes projectos digitais. Somos
            <span class="b">designers</span> que criam, adaptam ou integraram
            qualquer marca num ambiente digital e à qual os utilizadores reagem.
            Somos <span class="b">developers</span> com a capacidade de
            transformar qualquer sonho num produto digital, completo e funcional
            em qualquer dispositivo. Somos
            <span class="b">programadores</span> capazes de integrar qualquer
            funcionalidade.
          </p>
          <p v-if="lang == 'pt'">
            Acreditamos que só é possível desenvolvermos relações de sucesso se
            conversarmos com os nossos clientes.
          </p>
          <p v-if="lang == 'en'">
            We believe that it is only possible to develop successfull bussiness
            relations if we mantain a fluid concersation with our clients.
          </p>
          <a
            v-scroll-to="{
              el: '#contacts',
              offset: -110,
            }"
            v-if="lang == 'pt'"
            >Vamos conversar<span class="btn-dash"></span
          ></a>
          <a
            v-scroll-to="{
              el: '#contacts',
              offset: -110,
            }"
            v-if="lang == 'en'"
            >Let's talk<span class="btn-dash"></span
          ></a>
        </div>
      </div>
      <div class="about-background">
        <img
          alt="design-developmet-freelancer"
          src="@/assets/images/about.png"
        />
      </div>
    </section>

    <section id="testemunhos" class="testemunhos">
      <v-carousel hide-delimiters height="auto" :show-arrows="testemunhos.length > 1">
        <v-carousel-item v-for="(item, i) in testemunhos" :key="i">
          <v-row class="fill-height" align="center" justify="center">
            <div class="img-container">
              <img :src="'./assets/images/' + item.image" />
            </div>
            <div>
              <p v-if="lang == 'pt'" class="italic">"{{ item.text }}"</p>
              <p v-if="lang == 'en'" class="italic">"{{ item.textEN }}"</p>
            </div>
            <div>
              <p>
                {{ item.name }}, <b>{{ item.company }}</b>
              </p>
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </section>

    <section id="contacts" class="contacts">
      <div class="wrapper">
        <div class="contacts-content">
          <h2 v-if="lang == 'pt'">Contacte-nos</h2>
          <h2 v-if="lang == 'en'">Contact Us</h2>
          <p v-if="lang == 'pt'">
            Envie-nos uma mensagem se nos quiser conhecer ou tiver alguma
            dúvida.
          </p>
          <p v-if="lang == 'en'">
            Send us a mensage if you have any question or if you just want to
            get to know us.
          </p>
          <div id="form" class="form">
            <v-col cols="12">
              <v-text-field
                v-model="name"
                :label="lang == 'pt' ? 'Nome*' : 'Name*'"
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email*"
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="phone"
                :label="lang == 'pt' ? 'Telefone*' : 'Phone*'"
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12">
              <v-select
                v-model="subject"
                dense
                :items="lang == 'pt' ? items : itemsEN"
                :label="lang == 'pt' ? 'Assunto*' : 'Subject*'"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                v-model="message"
                :label="lang == 'pt' ? 'Mensagem*' : 'Message*'"
                value=""
                dense
              ></v-textarea>
            </v-col>
            <div class="alertMessage">{{ errMsg }}</div>
            <a v-if="lang == 'pt'" @click="submeter()"
              >Enviar Mensagem<span class="btn-dash"></span
            ></a>
            <a v-if="lang == 'en'" @click="submeter()"
              >Send Message<span class="btn-dash"></span
            ></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: ["lang"],
  data: () => ({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    errMsg: "",
    itemsPT: [
      "Mais informações",
      "Pedido de Orçamento",
      "Dúvidas sobre pagamentos e facturação",
      "Marcar Reunião",
      "Outro",
    ],
    itemsEN: [
      "More Information",
      "Budget Request",
      "Questions About Payments & Invoices",
      "Schedule Meeting",
      "Other",
    ],
    projects: [
      {
        name: "Platafoma de E-learning, Eventos e Portfólio",
        subtitle: "Biocodex",
        images: [
          {
            src: "portfolio-1.jpg",
          },
          {
            src: "portfolio-10.jpg",
          },
          {
            src: "portfolio-11.jpg",
          },
          {
            src: "portfolio-12.jpg",
          },
          {
            src: "portfolio-13.jpg",
          },
        ],
        text: "Plataforma de e-learning com mais de 2000 utilizadores em pouco mais de um ano. A plataforma integra também secções de portfólio, notícias, vídeos e eventos - webinares em tempo real com possibilidade de testar os conhecimentos dos utilizadores e atribuir-lhes créditos para a Ordem dos Farmacêuticos. A plataforma integra com outros sistemas e é gerida através de um <i>backoffice</i> desenvolvido de raíz para a Biocodex. A Linqode foi responsável pelo projecto de A a Z, o que incluí, estudo de usabilidade, design, front-end, back-end da plataforma e backoffice. É um projecto em evolução com novas funcionalidades todos os meses. A Linqode presta actualmente também os serviços de monitorização e manutenção evolutiva.<br>A informação apresentada nas imagens é apenas ilustrativa.",
        textEN: "E-learning platform with more than 2000 users in just a year. This platform includes also a portfolio, news, videos and events sections – webinars in real time with the possibility to test users knowledge and award them with Ordem dos Farmacêuticos ‘s credits. This platform is integrated with other systems and is managed through a content management system developed specifically for Biocodex. Linqode worked from the user experience, to the design, front-end, back-end of the platform and the CMS. It’s a working in progress project with new features every month. Linqode currently also provides monitoring and evolutionary maintenance services. The image data is only for illustrative purposes.",
        link: "https://mybiocodex.pt",
      },
      {
        name: "CMS",
        subtitle: "Biocodex",
        images: [
          {
            src: "portfolio-4.jpg",
          },
          {
            src: "portfolio-14.jpg",
          },
        ],
        text: "<i>Content Management Systems</i> (CMS) desenvolvido de raíz para a Biocodex de forma a otimizar a experência de utilização. Através do CMS a Biocodex tem a possibilidade de gerir os conteúdos e acompanhar em tempo real a interactividade dos utilizadores através de relatórios. Ferramenta de acesso limitado. A Linqode foi responsável pelo projecto de A a Z, o que incluí, estudo de usabilidade, design, front-end, back-end. É um projecto em evolução com novas funcionalidades todos os meses. A Linqode presta actualmente também os serviços de monitorização e manutenção evolutiva.<br>A informação apresentada nas imagens é apenas ilustrativa.",
        textEN: "Content Management Systems (CMS) developed specifically for Biocodex in order to optimize the user experience. Through the CMS, Biocodex can manage the content and follows through reports, in real time, the users interactivity. Access limited tool.  Linqode worked from the user experience, to the design, front-end, back-end of the CMS. It is a continuous working project with new features every month. Linqode currently also provides monitoring and evolutionary maintenance services. The image data is only for illustrative purposes.",
        link: "",
      },
      {
        name: "E-commerce",
        subtitle: "Auris",
        images: [
          {
            src: "portfolio-9.jpg",
          },
          {
            src: "portfolio-6.jpg",
          },
          {
            src: "portfolio-7.jpg",
          },
          {
            src: "portfolio-8.jpg",
          },
        ],
        text: "Loja online de vinhos Portugueses na Suíça. Projecto desenvolvido de raíz sem recurso a templates pré-definidos. A loja é gerida através de um backoffice desenvolvido especificamente para a Auris e está preparada para integrar posteriormente com o sistema de stock, armazenamento e entregas. Todos os projectos Linqode podem evoluir ao ritmo do cliente. A Linqode foi responsável pelo projecto de A a Z, o que incluí, estudo de usabilidade, design, front-end, back-end da loja e backoffice.",
        textEN: "Wine Portuguese e-commerce in Switzerland. Developed specifically for this purpose, without any pre-designed template. This shop is managed through a backoffice developed specifically for Auris CMS, which is prepared to perform an adequate interface with the stock inventory , storage and delivery system. Every Linqode project is able to grow with the client needs. Linqode worked from the user experience, to the design, front-end, back-end of the shop and the CMS.",
        link: "https://auriscollection.com",
      },
      {
        name: "Sales TV",
        subtitle: "Perrigo",
        images: [
          {
            src: "portfolio-5.jpg",
          },
        ],
        text: "A Sales TV foi um projecto sonhado pela equipa da Perrigo para as suas equipas comerciais e desenvolvido pela Linqode. Permite através de gráficos, em tempo real, partilhar com a equipa os dados das vendas. Tem também informação automática dos últimos post de instagram das contas da Perrigo, a meteorologia e o tráfego na zona onde se situa a sede. A Linqode foi responsável pelo projecto de A a Z, o que incluí, estudo de usabilidade, design, front-end, back-end da <i>Sales</i> TV e <i>backoffice</i>.<br>A informação apresentada nas imagens é apenas ilustrativa.",
        textEN: "The Sales TV was a 'dreamed' project by Perrigo to their commercial teams and developed by Linqode. Through a graphic system, allows in real time, sales info sharing with teams. Updated information concerning the last two instagram posts from Perrigo accounts, the weather forecast and traffic around the office is also provided. Linqode worked from the user experience, to the design, front-end, back-end of the platform and the CMS. It’s a continuous project with new features every month. Linqode currently provides monitoring and evolutionary maintenance services. The image data is only for illustrative purposes.",
        link: "",
      },
      {
        name: "Site Institucional",
        subtitle: "Colégio Mãe Maria",
        images: [
          {
            src: "portfolio-2.jpg",
          },
          {
            src: "portfolio-15.jpg",
          },
          {
            src: "portfolio-16.jpg",
          },
          {
            src: "portfolio-17.jpg",
          },
        ],
        text: "Website institucional de apresentação da empresa, seus serviços, instalações e contactos. Ferramenta de pré-incrições e de marcação de visitas. A Linqode foi responsável pelo projecto de A a Z, o que incluí, estudo de usabilidade, design, front-end, back-end. ",
        textEN: "Institutional website presenting the company, it’s services, facilities and contacts. Pre-registration and schedule visits tools are available. Linqode worked from the user experience, to the design, front-end, back-end.",
        link: "https://colegiomaemaria.pt",
      },
    ],
    testemunhos: [
      {
        text: "A Linqode conta com colaboradores bastante experientes na área da programação e conseguem desenvolver soluções à medida daquilo que necessitamos para termos um impacto muito grande e bem sucedido com os projetos desenvolvidos. São sempre muito disponíveis e transparentes na sua comunicação e com foco na solução quando surgem novos desafios. Têm também uma excelente capacidade analítica e crítica e com excelentes aconselhamentos a nível de desenvolvimento de projetos, tanto de programação, como estruturais e de funcionalidade. Profissionalismo, rapidez, foco na solução, transparência são valores que caracterizam a Linqode. São um parceiro de confiança para qualquer projeto digital.",
        textEN: "Linqode has very experienced employees in the programming area and can develop solutions tailored to what we need to have a very large and successful impact with the developed projects. They are always very available and transparent in their communication and focused on the solution when new challenges arise. They also have an excellent analytical and critical capacity and excellent advice in what concerns project development, both in terms of programming, as well as in terms of structure and functionality. Professionalism, speed, focus on solutions, transparency are values that characterize Linqode. They are a reliable partner for any digital project.",
        image: "profile_susana.jpg",
        name: "Susana Veloso",
        company: "Biocodex",
      },
      // { text: "", textEN: "", image: "", name: "", company: "" },
      // { text: "", textEN: "", image: "", name: "", company: "" },
    ],
  }),
  methods: {
    async submeter() {
      event.preventDefault();

      this.errMsg = "";

      let errorMsgs = {
        name: "Introduza o seu nome",
        email: "Introduza o seu email",
        phone: "Introduza o seu telefone",
        phone2: "Introduza um número de telefone válido",
        subject: "Introduza o assunto da sua mensagem",
        message: "Escreva a sua mensagem",
        success:
          "A sua mensagem foi enviada com sucesso! Será contactado brevemente",
        fail: "Houve um erro no envio da sua mensagem. Por favor tente novamente mais tarde ou entre em contacto através de um dos canais alternativos",
        sending: "A enviar...",
      };

      let errorMsgsEN = {
        name: "Please enter your name",
        email: "Please enter your email",
        phone: "Please enter your phone",
        phone2: "Please enter a valid phone number",
        subject: "Please enter your subject",
        message: "Please enter your message",
        success:
          "Your message was been successfully sent. You will be contacted soon.",
        fail: "There was an error sending your message. Please try again later or contact us through one of the alternative channels",
        sending: "Sending...",
      };

      if (this.name == "") {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.name;
        } else {
          this.errMsg = errorMsgsEN.name;
        }
      } else if (this.email == "") {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.email;
        } else {
          this.errMsg = errorMsgsEN.email;
        }
      } else if (this.phone == "") {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.phone;
        } else {
          this.errMsg = errorMsgsEN.phone;
        }
      } else if (this.phone.length < 9) {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.phone2;
        } else {
          this.errMsg = errorMsgsEN.phone2;
        }
      } else if (this.subject == "") {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.subject;
        } else {
          this.errMsg = errorMsgsEN.subject;
        }
      } else if (this.message == "") {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.message;
        } else {
          this.errMsg = errorMsgsEN.message;
        }
      } else {
        if (this.lang == "pt") {
          this.errMsg = errorMsgs.sending;
        } else {
          this.errMsg = errorMsgsEN.sending;
        }

        let mensagem = `
          <p><b>Nome: </b>${this.name}</p>
          <p><b>Email: </b>${this.email}</p>
          <p><b>Telefone: </b>${this.phone}</p>
          <p><b>Assunto: </b>${this.subject}</p>
          <p><b>Mensagem: </b><br>${this.message}</p>
        `;

        let fd = new FormData();

        fd.append("mensagem", mensagem);

        var myHeaders = new Headers();

        var myInit = {
          method: "POST",
          headers: myHeaders,
          body: fd,
        };

        let app = this;

        fetch("/base_mailer/", myInit).then(async function (response) {
          let res = await response.text();

          console.log(res);

          if (res == "OK") {
            app.errMsg = errorMsgs.success;
          } else {
            app.errMsg = errorMsgs.fail;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.banner {
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .bg,
  .bg2 {
    position: absolute;
    animation: bg 40s ease-out 0s alternate infinite none running;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include responsive(0, 1024px) {
      animation: bg-mobile 40s ease-out 0s alternate infinite none running;
    }
  }
  .bg {
    z-index: 10;
  }
  .bg2 {
    z-index: 18;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      animation: dash 40s ease-out 0s alternate infinite none running;
    }
    @include responsive(0, 512px) {
      z-index: 10;
    }
  }
  .people1 {
    position: absolute;
    height: 70%;
    bottom: 0;
    left: calc(50% - 500px);
    z-index: 15;
    opacity: 1;
    @include responsive(0, 1024px) {
      height: 75%;
      left: calc(50% - 600px);
    }
    @include responsive(0, 768px) {
      left: calc(50% - 400px);
    }
    @include responsive(0, 512px) {
      left: calc(50% - 280px);
      height: 400px;
      @include responsive-height(700px, 1000px) {
        height: 500px;
        left: calc(50% - 320px);
      }
    }
  }
  .people2 {
    position: absolute;
    bottom: 5%;
    height: 85%;
    right: calc(50% - 500px);
    z-index: 15;
    opacity: 1;
    @include responsive(0, 1024px) {
      height: 75%;
      right: calc(50% - 600px);
    }
    @include responsive(0, 768px) {
      right: calc(50% - 400px);
    }
    @include responsive(0, 512px) {
      height: 435px;
      right: calc(50% - 220px);
      bottom: 15%;
      @include responsive-height(700px, 1000px) {
        height: 550px;
        right: calc(50% - 270px);
        bottom: 25%;
      }
    }
  }
  .signature {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: "MuseoModerno", cursive;
    font-size: 24px !important;
    font-weight: 200;
    z-index: 20;
    text-align: center;
    display: block;
    width: 100%;
    @include responsive(0, 512px) {
      font-size: 18px !important;
      max-width: 150px;
    }
    .bold {
      font-weight: 600;
    }
  }
  .breve {
    position: absolute;
    top: calc(50% + 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    z-index: 20;
    @include responsive(0, 512px) {
      font-size: 14px;
    }
  }
  .us {
    position: absolute;
    top: calc(50% + 90px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 400;
    z-index: 20;
    text-align: center;
    line-height: 28px;
    // background-color: white;
    display: block;
    padding: 20px;
    @include responsive(0, 512px) {
      top: calc(50% + 120px);
      font-size: 14px;
      width: 100%;
      font-size: 13px;
    }
    p {
      opacity: 0.8;
      @include responsive(0, 512px) {
        font-size: 13px;
        line-height: 23px;
        opacity: 1;
      }
    }
    a {
      color: black;
      display: inline-block;
      position: relative;
      font-weight: 400;
      text-decoration: none;
      .btn-dash {
        display: block;
        width: 30%;
        height: 12px;
        position: absolute;
        background-color: #f25835;
        left: -0px;
        bottom: -10px;
        z-index: -1;
        transition: all 1s;
        margin-left: 35%;
      }
      &:hover {
        font-weight: 300;
        .btn-dash {
          width: 65%;
        }
      }
    }
  }
  .dash {
    display: block;
    width: 80%;
    height: 80px;
    background-color: #f5f5f5;
    position: absolute;
    @include responsive(0, 512px) {
      height: 50px;
    }
    &.dash1 {
      width: 120vh;
      top: calc(50% - 100px);
      left: calc(50% + 100px);
      transform: translate(-50%, -50%) rotate(-45deg);
      transform-origin: center;
      @include responsive(0, 1024px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-65deg);
      }
    }
    &.dash2 {
      width: 70vw;
      top: calc(35% + 55px);
      left: 75%;
      transform: rotate(-45deg);
      transform-origin: center left;
      @include responsive(0, 1024px) {
        transform: rotate(-65deg);
        top: calc(65% + 44px);
      }
      @include responsive(0, 512px) {
        top: calc(65% + 27px);
      }
    }
    &.dash3 {
      width: 70vw;
      top: 35%;
      left: 75%;
      transform: rotate(45deg);
      transform-origin: center left;
      @include responsive(0, 1024px) {
        transform: rotate(25deg);
        top: 65%;
      }
    }
    &.dash4 {
      width: 70vw;
      top: calc(70% + 55px);
      right: 80%;
      transform: rotate(45deg);
      transform-origin: center right;
      z-index: 18;
      @include responsive(0, 1024px) {
        transform: rotate(25deg);
        top: calc(70% + 44px);
        right: 90%;
      }
      @include responsive(0, 512px) {
        transform: rotate(25deg);
        top: calc(70% + 27px);
      }
    }
    &.dash5 {
      width: 70vw;
      top: 70%;
      right: 80%;
      transform: rotate(-45deg);
      transform-origin: center right;
      z-index: 18;
      @include responsive(0, 1024px) {
        transform: rotate(-65deg);
        right: 90%;
      }
    }
  }
  .next {
    .arrow {
      width: 30px;
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 200;
      transition: all 0.2s;
    }
    &:hover {
      .arrow {
        width: 32px;
        bottom: 30px;
      }
    }
  }
}

@keyframes dash {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes bg-mobile {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(65deg);
  }
  35% {
    transform: rotate(65deg);
  }
  50% {
    transform: rotate(205deg);
  }
  60% {
    transform: rotate(205deg);
  }
  75% {
    transform: rotate(245deg);
  }
  85% {
    transform: rotate(245deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bg {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  35% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(185deg);
  }
  60% {
    transform: rotate(185deg);
  }
  75% {
    transform: rotate(225deg);
  }
  85% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.services {
  text-align: center;
  background-color: #f25835;
  padding: 100px 0;
  position: relative;
  @include responsive(0, 768px) {
    padding: 40px 0 20px 0;
  }
  .wrapper {
    h2 {
      text-align: center;
      font-family: "MuseoModerno", cursive;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 20px;
      color: white;
    }
    .sub-title {
      text-align: center;
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 45px;
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: white;
    }
    .services-articles {
      article {
        width: 25%;
        display: inline-block;
        padding: 25px 20px;
        vertical-align: top;
        font-family: "Montserrat", sans-serif;
        @include responsive(0, 1024px) {
          width: 33.3%;
        }
        @include responsive(0, 768px) {
          width: 50%;
        }
        @include responsive(0, 512px) {
          width: 100%;
          padding: 5px 20px;
        }
        h4 {
          font-family: "MuseoModerno", cursive;
          font-size: 70px;
          font-weight: $black;
          color: #fe7253;
          text-align: center;
          @include responsive(0, 512px) {
            font-size: 60px;
          }
        }
        h3 {
          font-family: "MuseoModerno", cursive;
          font-size: 25px;
          font-weight: $bold;
          text-align: center;
          margin-top: -60px;
          margin-bottom: 10px;
          color: white;
          @include responsive(0, 512px) {
            font-size: 22px;
            margin-top: -45px;
          }
        }
        p {
          text-align: center;
          font-family: "Montserrat", sans-serif;
          color: white;
          line-height: 22px;
        }
      }
    }
  }
}

.portfolio {
  text-align: center;
  padding-top: 100px;
  @include responsive(0, 512px) {
    padding-top: 40px;
  }
  .sub-title {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 400;
    @include responsive(0, 512px) {
      width: 90%;
      margin: 0 auto;
    }
  }
  ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
    margin: 0 auto;
    li {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      padding: 80px 0;
      @include responsive(0, 512px) {
        padding: 40px 0;
      }
      .img-container {
        height: 44vw;
        max-height: 500px;
        overflow: hidden;
        max-width: 1024px;
        width: 90vw;
        margin: 0 auto;
      }
      img {
        width: 100%;
      }
      h2 {
        text-align: center;
        margin-bottom: 5px;
        font-size: 18px;
        margin-top: 30px;
      }
      p {
        width: 90%;
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        &.subtitle {
          text-transform: uppercase;
          font-weight: 300;
          margin-bottom: 15px;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
      a {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: black;
        color: white;
        margin: 0 auto;
        text-decoration: none;
        text-align: center;
        line-height: 40px;
        font-size: 25px;
        margin-top: 20px;
      }
    }
    li:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}

.testemunhos {
  background-color: white;
  padding: 100px 0;
  text-align: center;
  .img-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  div {
    display: block;
    margin: 0 auto;
  }
  p {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    &.italic {
      font-style: italic;
      margin-bottom: 30px;
    }
  }
}
.about {
  position: relative;
  background-color: #f5f5f5;
  vertical-align: top;
  z-index: 50;
  padding-bottom: 100px;
  @include responsive(0, 768px) {
    text-align: center;
    padding-bottom: 0px;
    padding-top: 50px;
  }
  @include responsive(0, 512px) {
    padding-top: 20px;
  }
  .wrapper {
    .about-content {
      width: 45%;
      display: inline-block;
      padding-top: 100px;
      @include responsive(0, 768px) {
        width: 100%;
        padding-top: 40px;
      }
      a {
        color: black;
        display: inline-block;
        position: relative;
        font-weight: 400;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        @include responsive(0, 512px) {
          font-size: 13px;
        }
        .btn-dash {
          display: block;
          width: 50%;
          height: 10px;
          position: absolute;
          background-color: #f25835;
          left: -0px;
          bottom: -12px;
          z-index: -1;
          transition: all 1s;
        }
        &:hover {
          font-weight: 300;
          .btn-dash {
            width: 100%;
          }
        }
      }
    }
  }
  .about-background {
    width: 50%;
    display: inline-block;
    position: absolute;
    bottom: -100px;
    right: 0;
    overflow: hidden;
    vertical-align: bottom;
    img {
      width: 550px;
    }
    @include responsive(0, 768px) {
      width: 100%;
      position: relative;
      right: 0;
      bottom: -75px;
      img {
        width: 420px;
      }
    }
    @include responsive(0, 512px) {
      bottom: -65px;
      img {
        width: 380px;
      }
    }
    @include responsive(0, 380px) {
      bottom: -55px;
      img {
        width: 300px;
      }
    }
  }
}

.contacts {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  @include responsive(0, 512px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .wrapper {
    .contacts-content {
      h2 {
        color: black;
        margin-bottom: 20px;
        text-align: center;
      }
      p {
        color: black;
        padding-bottom: 30px;
        text-align: center;
      }
    }
  }
  a {
    color: black;
    display: inline-block;
    position: relative;
    font-weight: 400;
    text-decoration: none;
    margin-top: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    @include responsive(0, 512px) {
      font-size: 13px;
    }
    .btn-dash {
      display: block;
      width: 30%;
      height: 10px;
      position: absolute;
      background-color: #f25835;
      left: -0px;
      bottom: -10px;
      transition: all 1s;
    }
    &:hover {
      font-weight: 300;
      .btn-dash {
        width: 100%;
      }
    }
  }
  .alertMessage {
    color: #f25835;
  }
}
</style>
