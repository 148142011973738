import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'pt',
  },
  mutations: {
    updateLang(state, newlang) {
      Vue.set(state,'lang', state.lang = newlang)
    }
  },
  getters: {
    getLang: state => state.lang
  },
  actions: {
    changePT(context) {
      context.commit('updateLang', 'pt')
    },
    changeEN(context) {
      context.commit('updateLang', 'en')
    },
  },
  modules: {
  }
})
